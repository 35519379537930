import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, CloseButton} from "react-bootstrap";
import ReactGoogleSlides from "react-google-slides";
const GoogleSlideModal = ({ name, slidesUrl, children }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button variant="link" className="me-2 mb-1" onClick={() => setShow(true)}>
        View Guide-r for {name}
      </Button>
      <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title>{name} Guide-r</Modal.Title>
          <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => setShow(false)}
          />
        </Modal.Header>
        <Modal.Body><ReactGoogleSlides
          width="100%"
          slidesLink={slidesUrl}
          slideDuration={5}
          position={1}
          showControls
          loop
        />{children}</Modal.Body>
      </Modal>
    </>
  );
}

GoogleSlideModal.propTypes = {
  name: PropTypes.string.isRequired,
  slidesUrl: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default GoogleSlideModal;