import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Link, useNavigate} from 'react-router-dom';
import AppContext from 'context/Context';
import Flex from 'components/common/Flex';
import GoogleSlideModal from 'components/xenon/GoogleSlideModal';
import ReactGoogleSlides from 'react-google-slides';
import apiRetrier from "api/api_retrier";
import CustomerApi from "api/customer";
import {Card} from "react-bootstrap";
import classNames from "classnames";

const Slides = ({
                  customerApi_ = CustomerApi,
                  customer_ = '',
                  errorLog_ = console.log,
                  maxApiRetry_ = -1
                }) => {
  const navigate = useNavigate();
  const [customer, setCustomer] = useState(customer_);
  const maxApiTime = 180000; //3 minutes

  const {
    config: {
      customerCode,
      apiUrl
    }
  } = useContext(AppContext);

  useEffect(() => {
    if (customerCode === '') {
      navigate('/');
      return;
    }
  }, [navigate, customerCode, apiUrl, errorLog_]);


  useEffect(() => {
    if (customerCode === 'demo') {
      setUpDemo();
      return;
    }

    if (customerCode) {
      let data = {token: customerCode};
      getCustomer(data);
    }

  }, [customerCode]);

  function getCustomer(data) {
    apiRetrier(() => customerApi_(apiUrl).fetch({data}), maxApiRetry_, maxApiTime)
      .then(response => {
        setCustomer(response);
      })
      .catch(error => {
        errorLog_(error);
      });
  }

  function setUpDemo() {
    setCustomer({
      name: "demo",
      email: "demo@email.com",
      google_slides:

      //   JSON.stringify({
      //   'Overview': {
      //     'All Sites': 'https://docs.google.com/presentation/d/1xzIA0VLDuhgi2lim0UV9_DD1orAxmZmYdkqvjNcIAkA/edit?usp=sharing',
      //   },
      //   'utah.edu': {
      //     'Item A.1': 'https://docs.google.com/presentation/d/1xzIA0VLDuhgi2lim0UV9_DD1orAxmZmYdkqvjNcIAkA/edit?usp=sharing',
      //     'Item A.2': 'https://www.google.com',
      //   },
      //   'mountians': {
      //     'Item B.1': 'https://www.google.com',
      //     'Item B.2': 'https://www.google.com',
      //   }
      // })
      'https://docs.google.com/presentation/d/1xzIA0VLDuhgi2lim0UV9_DD1orAxmZmYdkqvjNcIAkA/edit?usp=sharing'
    });
  }

  if (customerCode === '' || !customer.google_slides) return null;

  function jsxFor(json) {
    const entries = Object.keys(json);


    return (<>
      <Card className={'mt-3'}>
        <Card.Header className="bg-light">
          <Flex justifyContent="between">
            <h5 className="mb-1 mb-md-0">Guide-r Reports</h5>
          </Flex>
        </Card.Header>
      </Card>
      {entries.map((entry, index) => {
        const jsonEntry = json[entry];
        const subEntries = Object.keys(jsonEntry);
        return (
          <Card key={entry + index} className={'mt-3'}>
            <Card.Header className="bg-light">
              <Flex justifyContent="between">
                <h5 className="mb-1 mb-md-0">{entry}</h5>
              </Flex>
            </Card.Header>
            <Card.Body className="p-0">
              {subEntries.map((item, subIndex) => (
                <div className="notification"><GoogleSlideModal name={item} slidesUrl={jsonEntry[item]}/></div>
              ))}
            </Card.Body>
          </Card>
        )
      })}</>);
  }

  function jsxJsonString(str) {
    console.log(str)
    try {
      const json = JSON.parse(str);
      return jsxFor(json);
    } catch (e) {
      console.log(e)
      return (<>
        <Card className={'mt-3'}>
          <Card.Header className="bg-light">
            <Flex justifyContent="between">
              <h5 className="mb-1 mb-md-0">Guide-r Report</h5>
            </Flex>
          </Card.Header>
        </Card>
        <ReactGoogleSlides
          width="100%"
          slidesLink={str}
          slideDuration={5}
          position={1}
          showControls
          loop
        />
      </>);
    }
  }

  const jsx = jsxJsonString(customer.google_slides)

  return (jsx);
};
Slides.propTypes = {
  errorLog_: PropTypes.func,
};

export default Slides;
